<template>
    <div class="metric-filter-builder">
        <div class="row">
            <div class="col-6 mb-3">
                <label class="form-label">Display Name</label>
                <input type="text" id="name-input" class="form-control" v-model="local_mf_selected.name"
                    :class="((errors.admin_name != '' )? 'is-invalid':'')" @change="clearErrorForField('name')">
                <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                    {{errors.name }}
                </div>
            </div>
            <div class="col-6 mb-3">
                <label class="form-label">Admin Name</label>
                <input type="text" id="name-input" class="form-control" v-model="local_mf_selected.admin_name"
                    :class="((errors.admin_name != '' )? 'is-invalid':'')" @change="clearErrorForField('admin_name')">
                <div class="text-danger invalid-feedback" v-if="errors.admin_name != ''">
                    {{errors.admin_name }}
                </div>
            </div>

            <div v-if="type.toLowerCase() == 'metric'" class="col-6">
                <div class="mb-3">
                    <label class="form-label">Format</label>
                    <select class="form-select" :class="((errors.format != '' )? 'is-invalid':'')"
                    id="format-select" v-model="local_mf_selected.format" @change="selectFormat(local_mf_selected.format)">
                        <option value="" disabled selected>Select the format...</option>
                        <option value="money">Money</option>
                        <option value="percent">Percent</option>
                        <option value="number">Number</option>
                        <option value="string">String</option>
                    </select>
                    <div class="text-danger invalid-feedback" v-if="errors.format != ''">
                        {{errors.format}}
                    </div>
                </div>
            </div>
            <div v-if="type.toLowerCase() == 'metric'" class="col-6">
                <div class="mb-3">
                    <label class="form-label">Preferred Color (suggested based on Format type)</label>
                    <select class="form-select" :class="((errors.color != '' )? 'is-invalid':'')"
                    id="color-select" v-model="local_mf_selected.color" @change="clearErrorForField('color')">
                        <option value="" disabled selected>Select the color...</option>
                        <option value="yellow">Yellow</option>
                        <option value="green">Green</option>
                        <option value="blue">Blue</option>
                        <option value="purple">Purple</option>
                    </select>
                    <div class="text-danger invalid-feedback" v-if="errors.color != ''">
                        {{errors.color}}
                    </div>
                </div>
            </div>      
        </div>

        <div class="row">
            <div class="col mb-3">
                <label class="form-label">Description</label>
                <input class="form-control"
                    name="mf_description" v-model="local_mf_selected.description"/>
            </div>
            <div class="col-3 mb-3" v-if="type.toLowerCase() == 'metric'" >
                <label class="form-label">Importance <i class="fas fa-info-circle ms-2" data-bs-toggle="tooltip" title="Rate the importance of this metric with regards to a fundraising program on a scall from 1 to 5"></i> </label>
                <select class="form-select" id="importance" v-model="local_mf_selected.importance">
                    <option value="0.1">1 - Not Very Important</option>
                    <option value="0.3">2</option>
                    <option value="0.5">3</option>
                    <option value="0.7">4</option>
                    <option value="0.9">5 - Most Important</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="form-label">Formula (<small>Use <code v-pre>&lbrace;&lbrace;client.id&rbrace;&rbrace;</code> to insert the client ID into the formula</small>)</label>
                    <div class="input-group" >
                        <textarea class="full-height form-control formula-input" @change="validateFormula();"  @input="resizeTextarea()" v-model="local_mf_selected.formula"
                          name="mf_description" :class="{'is-invalid':errors.formula != '', 'is-valid' : (errors.formula == '' && local_mf_selected.formula.trim() != '' && !is_validating), 'is-validating' : is_validating}"></textarea>
                        <v-select id="formula-column-toggle " :options="local_columns" v-model="column_selected"
                            :searchable="true" class="searchable-select" placeholder="Columns"
                            :selectable="(option) => option.text != ''"
                            label="text" @search="fetchOptions"
                            @input="addColumnToText(column_selected)"
                            :filterable="false">
                            <template #selected-option="{ text, category }">
                            {{'Columns'}}
                            </template>

                            <template #open-indicator="{ attributes }">
                            <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='white' stroke='#ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                            </template>

                            <template #option="{ text, category }">
                            <div v-if="text == ''" :data-category="category" class="category-header"
                                v-on:click="return expandCategory(category, null)"
                                data-isexpanded="false">
                                {{ category }} <i class="fa-solid fa-caret-right"></i>
                            </div>
                            <div v-else class="suboption" :data-subcategory="category"
                                :class="category==null || category==''? 'show' : ''">
                                {{ text }}
                            </div>
                            </template>
                            <template #no-options="{ search, searching, loading }">
                                <div class="suboption show" v-if="is_loading">
                                    <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                                </div>
                                <div class="suboption show" v-else>
                                    <em>No results found</em>
                                </div>
                            </template>
                        </v-select>
                    </div>

                    <div class="text-danger invalid-feedback" v-if="errors.formula != ''">
                        {{errors.formula}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col">
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an error saving the {{this.type}} information.
                </div>
                <!-- Update Button -->
                <button type="submit" class="btn btn-primary" id="save-button"
                        @click.prevent="update"
                        :disabled="form.busy || is_validating">
                    <i class="fa fa-btn fa-save"></i> {{ (this.local_mf_selected.id > 0 ? "Update" : "Save" ) }}
                </button>
            </div>
        </div>
 
    </div>
</template>

<script>
import vSelect from "vue-select";
    export default {
        components: {
            vSelect
        },
        props:
            // client_id will be passed in from report_builder only
            ['data_blend', 'type', 'modal_id', 'default_metric_format', 'client_id'], 
        data() {
            return {
                local_mf_selected: null,
                form: {
                    busy: false,
                    error: false
                },
                errors :{
                    name: '',
                    admin_name: '',
                    format: '',
                    color: '',
                    formula: '',    
                },
                local_columns:[], //columns_filtered_by_data_blend_type,
                unfiltered_columns: [],
                column_selected: null,
                is_loading: false,
                is_validating: false,
            };
        },
        beforeMount() {
            this.local_mf_selected = this.getNewMetricFilter();
        },
        mounted() {
            this.loadColumnOptions();
        },
        updated(){
            this.resizeTextarea();

            // hide any tooltips shown
            document.querySelectorAll('.tooltip').forEach(e => e.remove());
        },
        watch:{
            type(val){
                this.local_mf_selected.type = this.type.toLowerCase();
            },
            client_id(val){
                this.loadColumnOptions();
            },
            data_blend(val){
                this.local_mf_selected.data_blend_id = (!this.clientSelected() ? null : this.data_blend.id);
                this.local_mf_selected.data_blend_type = this.data_blend.type;
                this.loadColumnOptions();
            },
            default_metric_format(val){
                if(this.default_metric_format){
                    this.local_mf_selected.format = 'percent';
                }
            }
        },
        methods: {
            setMetricFilter(mf) {
                if(mf== null)
                    this.local_mf_selected = this.getNewMetricFilter();
                else
                    this.local_mf_selected = mf;

                this.clearErrors();
                
                //Clear or revalidate the formula
                if(this.local_mf_selected.formula != "")
                    this.validateFormula();
                else
                    this.clearErrorForField('formula');

            },
            clientSelected() {
                // client_id is passed in from report_builder, client_id is null means it is a template report
                if (this.client_id == null){
                    return null;
                }
                return this.data_blend.client_id != null && this.data_blend.client_id > 0;
            },
            selectFormat(formatType){
                this.clearErrorForField('format')
                if(formatType == "money"){
                    this.local_mf_selected.color = 'green';
                } else if(formatType == "percent"){
                    this.local_mf_selected.color = 'blue';
                } else if(formatType == "number"){
                    this.local_mf_selected.color = 'yellow';
                } else {
                    this.local_mf_selected.color = 'purple';
                }
            },
            getNewMetricFilter() {
                return {
                    id: -1,
                    admin_name:'',
                    name: '',
                    type: this.type.toLowerCase(),
                    data_blend_type: this.data_blend.type,
                    data_blend_id: (!this.clientSelected() ? null : this.data_blend.id),
                    description: '',
                    importance: '',
                    formula: '',
                    format: this.default_metric_format? this.default_metric_format : null,
                    color: '*'
                }
            },
            clearErrors() { //reset errors to empty strings, except formula errors
                this.form.error = false;
                this.errors.admin_name = '';
                this.errors.name = '';
                this.errors.format = '';
                this.errors.color = '';
            },
            clearErrorForField(field_name){
                this.form.error = false;
                this.errors[field_name] = '';
            },
            hasErrors() {//validate the form
                if(this.local_mf_selected.admin_name == null || this.local_mf_selected.admin_name == ""){
                    this.errors.admin_name = "A name is required.";
                } else
                    this.errors.admin_name = "";
                if(this.local_mf_selected.name == null || this.local_mf_selected.name == ""){
                    this.errors.name = "A display name is required.";
                } else
                    this.errors.name = "";

                if(this.local_mf_selected.type == 'metric' && (this.local_mf_selected.format == null || this.local_mf_selected.format == "")) {
                    this.errors.format = "A format type is required for a metric.";
                } else
                    this.errors.format = "";

                if(this.local_mf_selected.type == 'metric' && (this.local_mf_selected.color == null || this.local_mf_selected.color == "")) {
                    this.errors.color = "A color is required for a metric.";
                } else
                    this.errors.color = "";

                if(this.local_mf_selected.formula == null || this.local_mf_selected.formula == "")
                    this.errors.formula = "A formula is required.";

                if ( this.errors.admin_name!="" || this.errors.format!="" || this.errors.color!="" || this.errors.formula!=""){
                    return true
                } else{
                    return false
                }
            },
            validateFormula(){
                this.clearErrorForField('formula');

                let mf = JSON.parse(JSON.stringify(this.local_mf_selected));
                mf.data_blend_id = this.data_blend.id;;
                // return true if formula is valid, return false if formula is invalid
                this.is_validating = true;
                let self = this;
                window.axios.post('/api/metric_filter/validate', mf)
                  .then(response => {
                    this.is_validating = false;
                    this.errors.formula = "";
                  }).catch(error => {
                    this.is_validating = false;
                    let err = error.response.data.message;
                    if(err && err.indexOf(":") == -1)
                        err = "Error: " + err;
                    this.errors.formula = err;
                  });
            },
            loadColumnOptions() {
                var data = {
                  include_trans: true,
                  source: 'data_blend',
                  data_source_id: this.data_blend.id
                };
                this.is_loading = true;
                window.axios.post('/api/bigquery/get_db_columns', data)
                  .then(response => {
                    var cols = response.data.columns;
                    var headers = "";
                    for(var i = 0; i < cols.length; i++){
                        //If it is in the custom struct and I'm working on the template data source
                        if(!this.clientSelected() && cols[i].value.indexOf(".custom") > -1){
                            cols.splice(i, 1);
                        }
                        else if(cols[i].value.indexOf(".") == -1){
                            cols[i].value = "c." + cols[i].value;

                        }

                        if(cols[i].category != null && headers != cols[i].category) {
                            headers = cols[i].category;
                            cols.splice(i, 0, {
                                value: "DIVIDER-"+ headers.trim(),
                                category: headers.trim(),
                                text: ""
                            });
                        }

                    }
                    this.local_columns = cols;
                    this.unfiltered_columns = JSON.parse(JSON.stringify(cols));
                    this.is_loading = false;
                  });
            },
            resizeTextarea() {
                var element = document.querySelectorAll('textarea')[0];
                    if (element && element.style){
                        element.style.height = '';
                        element.style.height = Math.max(element.offsetHeight, element.scrollHeight) + "px";
                                    }
                // this.$forceUpdate();                  
            },
            addColumnToText(valueToAdd) {
                var textField = document.getElementById(this.modal_id).getElementsByClassName('formula-input')[0];

                // Get the cursor's position
                const startPos = textField.selectionStart;
                const endPos = textField.selectionEnd;

                // Get the current value of the text field
                const currentValue = textField.value;

                // Construct the new value with the added text
                const newValue = currentValue.substring(0, startPos) + valueToAdd.value + currentValue.substring(endPos);

                // Set the new value to the text field
                textField.value = newValue;

                // Move the cursor after the added text
                const newCursorPos = startPos + valueToAdd.value.length;
                textField.setSelectionRange(newCursorPos, newCursorPos);

                this.local_mf_selected.formula = newValue;

                this.validateFormula();
            },
            expandCategory(cat, expand) {
                var headline = document.querySelector("div[data-category='"+cat+"']");
                var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");

                if(headline == undefined || lines == undefined)
                    return false;

                if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="block";
                    var divs = headline.getElementsByClassName("fa-caret-right");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-down");
                        divs[i].classList.remove("fa-caret-right");
                    }
                    headline.dataset.isexpanded = true;
                }
                else {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="none";
                    var divs = headline.getElementsByClassName("fa-caret-down");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-right");
                        divs[i].classList.remove("fa-caret-down");
                    }
                    headline.dataset.isexpanded = false;
                }

                return false;
            },

            fetchOptions (search, loading) {
                //Reset the array
                this.local_columns = JSON.parse(JSON.stringify(this.unfiltered_columns));

                if(search == "") {
                    return;
                }
                    
                this.is_loading = true;

                //Look at each column
                for(var i = this.local_columns.length-1; i >= 0 ; i--) {
                    //If the search string isn't in the text or the category
                    if(this.local_columns[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                        && (this.local_columns[i].category == null
                        || this.local_columns[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                        && this.local_columns[i].text != "" ) //And not a category divider

                        this.local_columns.splice(i, 1);
                }

                //Get the remaining categories
                var cats = [];
                for(var i = 0; i < this.local_columns.length; i++)
                    if(this.local_columns[i].category != null && !cats.includes(this.local_columns[i].category) && this.local_columns[i].text != "")
                        cats.push(this.local_columns[i].category);

                //Expand the categories
                for(var i = 0; i < cats.length; i++)
                    this.expandCategory(cats[i], true);

                //Remove a category if it isn't in the array of categories
                for(var i = this.local_columns.length-1; i >= 0 ; i--)
                    if(this.local_columns[i].text == "" && this.local_columns[i].category != null && !cats.includes(this.local_columns[i].category))
                        this.local_columns.splice(i, 1);

                this.is_loading = false;
                return true;
            },

            update() {
                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                this.form.busy = true;
                var self = this;

                // put a new object together
                if(this.local_mf_selected.id == -1 || this.local_mf_selected.id == null)
                    window.axios.post('/api/metric_filter', this.local_mf_selected)
                        .then(response => {
                            self.form.busy = false;
                            self.$emit('update', response.data.metric_filter)
                            self.local_mf_selected = self.getNewMetricFilter();
                        }).catch(error=> {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        });
                else
                    window.axios.put('/api/metric_filter/'+this.local_mf_selected.id, this.local_mf_selected)
                        .then(response => {
                            self.form.busy = false;
                            self.$emit('update', response.data.metric_filter)
                            self.local_mf_selected = self.getNewMetricFilter();
                        }).catch(error=> {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        });

            }
        }
    }
</script>
